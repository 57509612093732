import React from 'react';
import loadingIcon from '../assets/images/loadingIcon-light.gif';

const LoadingComponent = () => {
  return (
    <div className='mutation-loading '>
      <img width='200px' height='auto' alt='loading-icon' src={loadingIcon} />
    </div>
  );
};

export default LoadingComponent;
