import axios from 'axios';
import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';
import NotFoundComponent from './NotFoundComponent';
import PaymentForm from './PaymentForm';

const ParentComponent = ({ match }) => {
  const title = document.querySelector('title');

  const search = useLocation().search;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${match.params.fid}`
      );
      const data = res.data;
      setData(data);
      title.innerHTML = 'ایران درگاه - فرم ‌پرداخت ' + data.title;
    } catch (e) {
      console.log(e);
      setError(e);
    }
  }, []);
  return (
    <Fragment>
      {!data && !error ? (
        <LoadingComponent />
      ) : !data && error ? (
        <NotFoundComponent error={error} />
      ) : (
        <PaymentForm search={search} match={match} data={data} />
      )}
    </Fragment>
  );
};

export default ParentComponent;
