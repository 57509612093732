import React, { Fragment } from 'react';
import logoTypeLight from '../assets/images/logo-type.png';
import logoTypeDark from '../assets/images/logo-type-dark.png';

const LogoType = ({ color }) => {
  return (
    <Fragment>
      {color == 'light' || color == 'green' || color == 'yellow' ? (
        <img height='20px' width='auto' src={logoTypeDark} alt='logo-type' />
      ) : (
        <img height='20px' width='auto' src={logoTypeLight} alt='logo-type' />
      )}
    </Fragment>
  );
};

export default LogoType;
