import './App.css';
// import 'bootstrap/dist/css/bootstrap.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import NotFoundComponent from './components/NotFoundComponent';
import ParentComponent from './components';
import { ToastContainer } from 'react-toastify';
import { Fragment } from 'react/cjs/react.production.min';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};
  }
  return (
    <Fragment>
      <ToastContainer position='top-right' closeOnClick limit={1} />
      <Router>
        <Switch>
          <Route exact path='/404' component={NotFoundComponent} />
          <Route exact path='/:fid' component={ParentComponent} />
          <Redirect from='*' to='/404' />
        </Switch>
      </Router>
    </Fragment>
  );
};
export default App;
