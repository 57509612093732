import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/styles/style.scss';
import './assets/styles/inputList.scss';

import '@material/react-text-field/index.scss';
import './assets/fonts/AzarMehr/FD (Farsi digits)/variable/AzarMehr-VF-FD.ttf';

import App from './App';

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
