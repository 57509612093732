import React from 'react';
import curveLogo from '../assets/images/curve-logo-white.svg';
import logoType from '../assets/images/logo-type.png';

const NotFoundComponent = ({ error }) => {
  return (
    <div className='d-flex text-right default text-white align-items-center justify-content-center flex-column curve-container'>
      <div className='curve-card-error'>
        <span className='curve-section'>
          <img
            className='curve-section-logo'
            alt='curve-logo'
            src={curveLogo}
          />
        </span>
        <span className='message-section'>
          <h6
            className={`${
              error?.response?.status === 401 || error?.response?.status === 405
                ? 'text-warning'
                : 'text-danger'
            } mb-0 text-center error-message`}
          >
            {error?.response?.data?.error || 'لینک پرداخت یافت نشد!'}
          </h6>
        </span>
        <small className='text-secondary mb-2'>
          {error?.response?.status === 401
            ? 'از شکیبایی شما متشکریم'
            : 'برای اطلاعات بیشتر با پذیرنده خود تماس بگیرید'}
        </small>

        <span className='logo-type-section mt-3 mb-3'>
          <a target='_blank' href='https://irandargah.com'>
            <img height='20px' width='auto' src={logoType} alt='logo-type' />
          </a>
        </span>
      </div>
    </div>
  );
};

export default NotFoundComponent;
