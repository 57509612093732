import { Fragment, useEffect, useRef } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import {
  AvForm,
  AvInput,
  AvFeedback,
  AvGroup,
} from 'availity-reactstrap-validation-safe';

import SuccessResult from './successResult';
import ErrorComponent from './Error';

import logo from '../assets/images/blue_logo.svg';
import eNamad from '../assets/images/e-namad.png';
import shaparak from '../assets/images/shaparak.png';
import markaziBank from '../assets/images/markazi-bank.png';
import { useState } from 'react';
import { isOdd, TextErrors, userJSDecrypt } from '../utility';
import axios from 'axios';
import { toast } from 'react-toastify';

import curveLogo from '../assets/images/curve-logo.svg';
import LogoType from '../utility/LogoType';
import InputList from '../utility/InputList';

const PaymentForm = ({ search, data, match }) => {
  const [decrypted, setDecrypted] = useState();

  if (!data.amount && data.new_url && data.new_url.length !== 0) {
    data.amount = data.new_url[0].price;
  }
  useEffect(() => {
    if (search) {
      const params = search.split('?')[1];
      const decrypted = userJSDecrypt('irandargah.com', params);
      setDecrypted(JSON.parse(decrypted));
    }
  }, []);

  const code = new URLSearchParams(decrypted).get('code');
  const refId = new URLSearchParams(decrypted).get('refId');
  const message = new URLSearchParams(decrypted).get('message');
  const amount = new URLSearchParams(decrypted).get('amount');
  const redirectUrl = new URLSearchParams(decrypted).get('redirect_url');

  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [colorPackage, setColorPackage] = useState(
    data.color_package || 'default'
  );

  const errorColor =
    colorPackage == 'purple' || colorPackage == 'blue'
      ? 'text-danger-light'
      : colorPackage == 'red' ||
        colorPackage == 'pink' ||
        colorPackage == 'darkTurquoise'
      ? 'text-danger-dark'
      : 'text-danger';

  const [state, setState] = useState({
    mobile: '',
    price: data.amount,
    description: data.description,
    title: data.title,
    extraFields: data.extra_fields || [],
    extraFieldValues: [],
    logo: `https://api.irandargah.com/terminals/${
      data.image ? data.image : data.terminal.logo
    }`,
    terminal: data.terminal,
    singleUse: data.single_use,
    isActive: data.is_active,
    expireAt: data.expireAt,
    quantity: data.quantity,
  });

  const handleChangeInput = (event) => {
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const submitForm = async (e, errors) => {
    e.preventDefault();
    const payload = {
      mobile: state.mobile,
      amount: parseInt(state.price),
      extra_field_values: JSON.stringify(Object.values(state.extraFieldValues)),
      quantity: counter,
    };
    if (!data.quantity) {
      delete payload.quantity;
    }
    if (!errors.length) {
      if (payload.amount >= 50000 && payload.amount < 500000000) {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/${match.params.fid}`,
            payload
          );
          const { status, authority, message } = res.data;
          if (status === 200) {
            window.location.href = `https://dargaah.com/ird/startpay/${authority}`;
          } else {
            setLoading(false);
            toast.error(message);
          }
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      } else {
        toast.error(TextErrors.invalidAmountRial);
      }
    }
  };

  useEffect(() => {
    if (data.amount && state.quantity && state.quantity > 1) {
      setState({
        ...state,
        price: data.amount * counter,
      });
    }
  }, [counter]);

  return code && code == 100 ? (
    <SuccessResult
      refId={refId}
      message={message}
      amount={amount}
      match={match}
      redirectUrl={redirectUrl}
    />
  ) : code && code != -1 ? (
    <ErrorComponent message={message} />
  ) : (
    <div className={`${colorPackage} curve-container mt-2} `}>
      <div className='top-section'>
        <div className='store-logo  mb-2 mt-2 align-content-around'>
          {data.image || data.terminal.logo ? (
            <img
              alt='تصویر محصول'
              className='rounded-pill border'
              width='60px'
              height='60px'
              src={state.logo}
            />
          ) : (
            <span className=' rounded-pill p-1'>
              <img
                alt='تصویر محصول'
                className='p-2'
                width='60px'
                height='60px'
                src={logo}
              />
            </span>
          )}
        </div>
        <span className='title-section ms-3 me-3'>
          <h6 className='mb-0 text-center fw-bold'>{state.title}</h6>
        </span>

        <div className='terminal-section ps-2 pe-2 '>
          <div>
            <h6 className='fw-bold mb-0'>{state.terminal.farsiName}</h6>
          </div>
        </div>
        <div className='w-100'>
          <div className='divider mb-3'></div>
        </div>
        <div className='mb-4'>
          <span className='text-center text-secondary-light description-section'>
            {state.description.split('\n').map((item, index) => (
              <div key={index} className='my-0 ps-3 pe-3'>
                {item === '' ? <br /> : <span>{item}</span>}
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className='curve-card'>
        <span className='curve-section'>
          <img
            className='curve-section-logo'
            alt='curve-logo'
            src={curveLogo}
          />
        </span>
        <div
          className={`${
            state.price ? 'amount-section' : 'amount-section-default'
          } mt-2 mb-2 fw-bold`}
        >
          {state.price ? (
            <Fragment>
              <span>{parseInt(state.price).toLocaleString()}</span>
              <span> ریال </span>
            </Fragment>
          ) : (
            <span></span>
          )}
        </div>

        <div className='form-section mt-2 mt-sm-2'>
          <AvForm onSubmit={submitForm} className='d-flex flex-column  '>
            <Row className='d-flex justify-content-center align-items-start'>
              {!data.amount && (
                <Col
                  md={state.extraFields.length > 1 ? '6' : '10'}
                  className='d-flex justify-content-center p-0 '
                >
                  <AvGroup
                    className={`group ms-2 me-2 mb-3  flex-column d-flex justify-content-center`}
                  >
                    <AvInput
                      required
                      name='price'
                      type='number'
                      pattern='.{5}'
                      value={state.price}
                      onChange={handleChangeInput}
                    />
                    <label className='px-2'>مبلغ</label>
                    <AvFeedback>
                      <small className={errorColor}>
                        {TextErrors.invalidAmountRial}
                      </small>
                    </AvFeedback>
                  </AvGroup>
                </Col>
              )}
              <Col
                md={state.extraFields.length > 1 ? '6' : '10'}
                className='d-flex justify-content-center p-0'
              >
                <AvGroup
                  className={`group ms-2 me-2 mb-3 flex-column d-flex justify-content-center `}
                >
                  <AvInput
                    required
                    pattern='.{11}'
                    maxLength='11'
                    name='mobile'
                    value={state.mobile}
                    onChange={handleChangeInput}
                  />
                  <label className='px-2'>شماره موبایل</label>
                  <AvFeedback>
                    <small className={errorColor}>
                      {TextErrors.invalidCellphone}
                    </small>
                  </AvFeedback>
                </AvGroup>
              </Col>
              {Object.keys(state.extraFields).map((item, index) =>
                state.extraFields[item]?.isActive ? (
                  <Col
                    key={index}
                    md={
                      (state.extraFields.length == index + 1 &&
                        isOdd(index) &&
                        index != 0 &&
                        data.amount) ||
                      (state.extraFields.length == index + 1 &&
                        !isOdd(index) &&
                        index != 0 &&
                        !data.amount)
                        ? '12'
                        : state.extraFields.length > 1
                        ? '6'
                        : '10'
                    }
                    className='d-flex justify-content-center p-0'
                  >
                    {state.extraFields[item].type === 'DROPDOWN' ? (
                      <InputList
                        item={item}
                        setState={setState}
                        options={state.extraFields[item].options}
                        state={state}
                        errorColor={errorColor}
                      />
                    ) : (
                      <AvGroup
                        className={`group ms-2 me-2 mb-3  flex-column d-flex justify-content-center`}
                      >
                        <AvInput
                          required={state.extraFields[item].isRequired}
                          id={item + 'title'}
                          name={item + 'title'}
                          type={
                            state.extraFields[item]?.type === 'NUMERIC'
                              ? 'number'
                              : 'text'
                          }
                          value={state.extraFieldValues[item]?.value}
                          onChange={({ target }) =>
                            setState({
                              ...state,
                              extraFieldValues: {
                                ...state.extraFieldValues,
                                [item]: {
                                  ...state.extraFieldValues[item],
                                  title: state.extraFields[item].title,
                                  value: target.value,
                                },
                              },
                            })
                          }
                        />
                        <label className=' px-2'>
                          {state.extraFields[item].title}
                        </label>
                        <AvFeedback>
                          <small className={errorColor}>
                            {TextErrors.empty}
                          </small>
                        </AvFeedback>
                      </AvGroup>
                    )}
                  </Col>
                ) : null
              )}
              {state.quantity && data.amount ? (
                <Col
                  className='d-flex justify-content-center mb-1 m-0 p-0'
                  md='12'
                >
                  <div className='mb-2 d-flex counter-section p-2 justify-content-between align-items-center'>
                    <span
                      className='rounded cursor-pointer btn-plus border-0 pe-3'
                      onClick={() => {
                        if (counter < state.quantity) setCounter(counter + 1);
                      }}
                    >
                      +
                    </span>
                    <span className='mx-3'>{counter}</span>
                    <span
                      className='rounded cursor-pointer me-2 border-0 btn-minus  ps-3'
                      onClick={() => {
                        if (counter > 1) {
                          setCounter(counter - 1);
                        }
                      }}
                    >
                      -
                    </span>
                  </div>
                </Col>
              ) : (
                ''
              )}
              <Row className='d-flex justify-content-center p-0'>
                <Col
                  md={state.extraFields.length > 1 ? '6' : '10'}
                  className={`ps-2 pe-2`}
                >
                  <Button
                    disabled={loading}
                    type='submit'
                    className={`mb-3 payment-btn w-100`}
                    color='success'
                  >
                    {loading ? (
                      <Spinner size='sm' color='primary' children={false} />
                    ) : (
                      <span className='text-white'>پرداخت</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Row>
          </AvForm>
        </div>
        <span className='logo-type-section mt-3 mb-3'>
          <a target='_blank' href='https://irandargah.com'>
            <LogoType color={colorPackage} />
          </a>
        </span>
      </div>
      <div className='mt-3'>
        <img alt='ای‌نماد' className='ms-1 e-namad-logo' src={eNamad} />
        <img alt='شاپرک' className='ms-1 e-namad-logo' src={shaparak} />
        <img alt='بانک مرکزی' className='e-namad-logo' src={markaziBank} />
      </div>
    </div>
  );
};

export default PaymentForm;
