import CryptoJS from 'crypto-js';

export const numFormatter = (num) => num?.toLocaleString();
export const isOdd = (num) => {
  let isOdd = num % 2;
  return isOdd == 1 ? true : false;
};

export const TextErrors = {
  empty: 'فیلد نمیتواند خالی باشد',
  emptyTerminal: 'درگاه مستقیم جهت نمایش تراکنش انتخاب کنید',
  notMatchPassword: 'رمز عبور با تکرارش همخوانی ندارد',
  invalid: 'فیلد نامعتبر است',
  invalidAmountRial: 'مبلغ باید بین 50،000 ریال تا 500،000،000 ریال باشد.',
  invalidAmountToman: 'مبلغ باید بین 1000 تومان تا 50،000،000 تومان باشد.',
  invalidNationalCode: 'کد ملی نامعتبر است',
  invalidNationalId: 'شناسه ملی نامعتبر است',
  invalidNationalLegalCode: 'شناسه ملی نامعتبر است',
  invalidCellphone: 'شماره همراه نامعتبر است',
  invalidPassword: 'رمز عبور نامعتبر است',
  correctPasswordNumber: 'رمز عبور میبایست حداقل شامل یک عدد باشد.',
  correctPasswordPattern:
    'رمز عبور میبایست حداقل شامل یکی از کارکتر های !@#$%^& باشد.',
  invalidTelephone: 'شماره ثابت نامعتبر است',
  invalidIpAddress: 'آی‌پی نامعتبر است',
  invalidCallbackAddress: 'آدرس بازگشتی نامعتبر است',
  invalidEmailAddress: 'ایمیل نامعتبر است',
  invalidTaxPayerCode: 'کدرهگیری مالیاتی نامعتبر است',
  invalidWebsiteAddress: 'وبسایت نامعتبر است',
  invalidPostalCode: 'کد پستی نامعتبر است',
  invalidSheba: 'شماره شبا نامعتبر است',
  invalidShebaCount: 'شماره شبا می بایست ۲۴رقم باشد',
  invalidConfirmation: 'کد را بصورت کامل وارد کنید',
  invalidImageSize: 'سایز عکس انتخابی می بایست کمتر از ۲ مگابایت باشد',
  invalidImageType: 'تایپ عکس انتخابی صحیح نیست',
  invalidSelection: 'مورد انتخابی صحیح نیست',
  minLength: 'رمز عبور باید بیشتر از  7 کاراکتر باشد',
  correctPasswordText: 'رمز عبور می بایست شامل حداقل یک حرف باشد',
  invalidAmount: 'مقدار وارد شده صحیح نیست',
  invalidFile: 'فایل انتخابی صحیح نیست',
};

const getKey = (passphrase, salt) => {
  const iterations = 999;
  var key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA256,
    keySize: 64 / 8,
    iterations: iterations,
  });
  return key;
};

export const userJSDecrypt = (passphrase, encryptedText) => {
  const salt = 'salt';
  const iv = '3699711930299708';
  var key = getKey(passphrase, salt);
  var decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv: CryptoJS.enc.Utf8.parse(iv),
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
