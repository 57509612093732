import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import curveLogo from '../assets/images/curve-logo-white.svg';
import logoType from '../assets/images/logo-type.png';

const SuccessResult = ({ match, message, amount, refId, redirectUrl }) => {
  const [redirectTime, setRedirectTime] = useState(5);

  if (redirectUrl && redirectUrl != 'null') {
    setTimeout(() => {
      setRedirectTime(redirectTime - 1);
    }, 1000);
  }

  const handleBack = () => window.location.replace(redirectUrl);

  useEffect(() => {
    if (redirectUrl && redirectUrl != 'null' && redirectTime == 0) {
      window.location.replace(redirectUrl);
    }
  }, [redirectTime]);

  return (
    <div className='d-flex text-right default text-white align-items-center justify-content-center flex-column curve-container'>
      <div className='curve-card-success'>
        <span className='curve-section'>
          <img
            className='curve-section-logo'
            alt='curve-logo'
            src={curveLogo}
          />
        </span>
        <span className='message-section'>
          <h6 className='text-success mb-0'>{message}</h6>
        </span>
        {amount && (
          <span className='d-flex flex-column gap-2 justify-content-center align-items-center'>
            <span className='text-secondary '>مبلغ</span>
            <h5 className='mb-4 mb-0'>
              {parseInt(amount).toLocaleString()} ریال
            </h5>
          </span>
        )}
        <span className='d-flex flex-column gap-2 justify-content-center align-items-center'>
          <span className='text-secondary '>کد پیگیری</span>
          <h5 className='mb-4'>{refId}</h5>
        </span>
        {redirectUrl && redirectUrl != 'null' && (
          <Fragment>
            <span className='d-flex flex-column gap-2 justify-content-center align-items-center'>
              <h4 className='mb-4 message-section'>{redirectTime}</h4>
            </span>
            <div>
              <Button
                block
                className='btn bg-info border-0 text-white px-5 rounded'
                onClick={handleBack}
              >
                ادامه
              </Button>
            </div>
          </Fragment>
        )}
        <span className='logo-type-section mt-3 mb-3'>
          <a target='_blank' href='https://irandargah.com'>
            <img height='20px' width='auto' src={logoType} alt='logo-type' />
          </a>
        </span>
      </div>
    </div>
  );
};

export default SuccessResult;
