import {
  AvFeedback,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation-safe';
import React, { Fragment, useRef, useState } from 'react';
import { TextErrors } from './index.js';
import { useOnClickOutside } from './useOnClickOutside.js';

const InputList = ({ options, setState, state, item, errorColor }) => {
  const selectInputContainer = useRef(false);

  const [showSuggests, setShowSuggests] = useState(null);

  useOnClickOutside(selectInputContainer, () => {
    setShowSuggests(false);
  });

  const selectSuggest = (option) => {
    setShowSuggests(false);
    setState({
      ...state,
      extraFieldValues: {
        ...state.extraFieldValues,
        [item]: {
          ...state.extraFieldValues[item],
          title: state.extraFields[item].title,
          value: option,
        },
      },
    });
  };

  return (
    <Fragment>
      <AvGroup
        className={`group  ms-2 me-2 mb-3 flex-column d-flex justify-content-center`}
      >
        <AvInput
          required
          onFocus={() => setShowSuggests(true)}
          name={item + 'title'}
          id={item + 'title'}
          value={state.extraFieldValues[item]?.value}
          readOnly
        />
        {showSuggests && (
          <div className='autocomplete-container' ref={selectInputContainer}>
            <div className='suggestions-list'>
              {JSON.parse(options).map((option, index) => (
                <span
                  key={index}
                  className='suggestion-item'
                  onClick={() => selectSuggest(option)}
                >
                  {option}
                </span>
              ))}
              {/* <span className='suggestion-item last-child'></span> */}
            </div>
          </div>
        )}
        <label className=' px-2'>{state.extraFields[item].title}</label>
        <AvFeedback>
          <small className={errorColor}>{TextErrors.empty}</small>
        </AvFeedback>
      </AvGroup>
    </Fragment>
  );
};

export default InputList;
